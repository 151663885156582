<template>
  <div class="relative form-reset">
    <input :type="currentType"
           :placeholder="label"
           :aria-placeholder="label"
           @input="handleInput"
           ref="input"
           :autocomplete="autocomplete ? 'on': 'off'"
           :readonly="!autocomplete"
           :value="internalValue"
           @focus="() => !autocomplete ? handleRemoveReadOnly() : null"
           aria-autocomplete="none"
           class="border border-gray-300 rounded-xl px-4 py-3 w-full font-light text-sm"
           :class="{'border-red-800': error !== null}"
    />
    <svg
        class="absolute right-5 top-4 mt-0.5 text-gray-300 cursor-pointer hover:text-black transition"
        :class="{'text-primary-500': !currentHideStatus}"
        @click="currentHideStatus = !currentHideStatus"
        xmlns="http://www.w3.org/2000/svg" width="21.215" height="13" viewBox="0 0 21.215 13" v-if="hideBehaviour">
      <g id="Oeil" transform="translate(-1117 -680)">
        <g id="Path_53" data-name="Path 53" transform="translate(1117 680)" fill="transparent">
          <path d="M 10.60742282867432 12.5 C 7.258292675018311 12.5 4.654542446136475 10.99271011352539 3.400562524795532 10.09422016143799 C 1.519872426986694 8.746700286865234 0.5024625062942505 7.235799789428711 0.5000025033950806 6.498330116271973 C 0.4985224902629852 6.055280208587646 0.8774924874305725 5.200029850006104 1.945562481880188 4.128389835357666 C 3.693782567977905 2.37431001663208 6.887312412261963 0.5 10.60742282867432 0.5 C 14.36667251586914 0.5 17.54509162902832 2.34128999710083 19.26612281799316 4.064459800720215 C 20.33516311645508 5.13484001159668 20.71543312072754 6.024089813232422 20.71484184265137 6.499380111694336 C 20.71411323547363 7.084070205688477 20.14763259887695 8.199540138244629 18.5599422454834 9.576700210571289 C 16.93779182434082 10.990309715271 13.99749279022217 12.5 10.60742282867432 12.5 Z" stroke="none"/>
          <path d="M 10.60742282867432 1 C 7.040382385253906 1 3.977092742919922 2.79835033416748 2.299713134765625 4.481340408325195 C 1.26976203918457 5.514729976654053 0.9991722106933594 6.25147008895874 0.9999828338623047 6.496660232543945 C 1.001691818237305 7.007009983062744 1.858383178710938 8.374149322509766 3.691781997680664 9.687780380249023 C 4.896991729736328 10.55132007598877 7.398062705993652 12 10.60742282867432 12 C 13.8593921661377 12 16.67755317687988 10.55387020111084 18.23231315612793 9.198989868164062 C 19.79632186889648 7.842380046844482 20.21438217163086 6.863359928131104 20.21484184265137 6.49875020980835 C 20.21520233154297 6.210579872131348 19.94128227233887 5.448009967803955 18.9123420715332 4.417799949645996 C 17.26213264465332 2.765529632568359 14.21383285522461 1 10.60742282867432 1 M 10.60742282867432 0 C 16.46573257446289 0 21.2175121307373 4.357239723205566 21.21484184265137 6.5 C 21.21374320983887 7.378530025482178 20.41522216796875 8.62932014465332 18.8875617980957 9.954410552978516 C 17.13318252563477 11.48324012756348 14.06382179260254 13 10.60742282867432 13 C 4.749101638793945 13 0.006683349609375 8.50177001953125 -7.62939453125e-06 6.5 C -0.006687164306640625 4.49822998046875 4.749101638793945 0 10.60742282867432 0 Z" stroke="none"  class="fill-current"/>
        </g>
        <circle id="Ellipse_6" data-name="Ellipse 6" cx="2" cy="2" r="2" transform="translate(1126.107 685)" class="fill-current"/>
      </g>
    </svg>
    <span v-if="error" class="text-sm text-red-800">{{ error }}</span>
    <span v-if="getError" class="text-sm text-red-800">{{ getError }}</span>
  </div>
</template>

<script>
import Vue from 'vue';
import { Component } from 'vue-property-decorator';
import { isValidEmail, isValidPassword } from '@/_helpers/validation.helper';

@Component({
  name: 'flat-basic-input',
  props: {
    label: {
      required: true,
      type: String,
    },
    error: {
      type: String,
      required: false,
      default: null,
    },
    value: {
      required: false,
      default: '',
      type: String,
    },
    type: {
      required: false,
      default: 'text',
      type: String,
    },
    hideBehaviour: {
      type: Boolean,
      required: false,
      default: false,
    },
    autocomplete: {
      type: Boolean,
      required: false,
      default: false,
    },
    validation: {
      type: String,
      default: 'none',
      required: false,
    },
  },
  watch: {
    value(v) {
      this.setValue(v);
    },
  },
  data() {
    return {
      internalValue: '',
      currentHideStatus: false,
    };
  },
  mounted() {
    if (this.hideBehaviour) {
      this.currentHideStatus = true;
    }
  },
  computed: {
    currentType() {
      if (this.currentHideStatus === true) return 'password';
      return this.type;
    },
    getError() {
      if (this.internalValue.length === 0) return null;
      if (this.validation === 'password' && !isValidPassword(this.internalValue)) {
        return 'Le mot de passe doit contenir au moins 1 chiffre, 1 majuscule, 1 minuscule et doit faire au moins 8 caractères';
      }
      if (this.validation === 'email' && !isValidEmail(this.internalValue)) {
        return 'L\'adresse email saisie n\'est pas valide';
      }
      return null;
    },
  },
  methods: {
    handleRemoveReadOnly() {
      const { input } = this.$refs;
      input.removeAttribute('readonly');
    },
    setValue(value) {
      this.internalValue = value;
      this.$emit('input', value);
    },
    handleInput(e) {
      this.$emit('event', e);
      this.setValue(e.srcElement.value);
    },
  },
})
export default class FlatBasicInput extends Vue {
}
</script>

<style scoped>

</style>
