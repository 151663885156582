// eslint-disable-next-line import/prefer-default-export
export function isValidPassword(password: string) {
  return password.length > 8
    && /(?=.*[0-9])/.test(password)
    && /(?=.*[a-z])/.test(password)
    && /(?=.*[A-Z])/.test(password);
}

export function isValidEmail(email: string) {
  /* eslint-disable-next-line no-useless-escape,max-len */
  const re = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
  return re.test(String(email).toLowerCase());
}
