<template>
  <div class="app-form">
    <div class="flex" v-if="loading">
      <loader size="128px" class="text-primary-500 mx-auto"></loader>
    </div>
    <form v-if="!loading">
      <slot></slot>
    </form>
  </div>
</template>

<script>
import Vue from 'vue';
import { Component } from 'vue-property-decorator';
import Loader from '../loader.vue';

@Component({
  name: 'app-form',
  components: { Loader },
  props: {
    loading: {
      required: false,
      default: false,
      type: Boolean,
    },
  },
  data() {
    return {};
  },
  computed: {},
  methods: {},
})
export default class AppForm extends Vue {
}
</script>

<style scoped>

</style>
