<template>
  <div>
    <div class="error-container mb-8" v-if="error">
      <div class="message mb-2 rounded bg-red-600 text-white border border-red-800 rounded px-3 py-2">
        <div class="text-sm">{{ error }}</div>
      </div>
    </div>
    <app-form :loading="loading">
      <div class="mb-4">
        <flat-basic-input label="Email" type="email" v-model="email" :autocomplete="true"></flat-basic-input>
      </div>
      <div class="mb-4">
        <flat-basic-input label="Mot de passe" v-model="password" :hide-behaviour="true" :autocomplete="true"></flat-basic-input>
      </div>
      <div class="grid sm:grid-cols-2 items-center gap-4 mb-4">
        <div>
          <flat-checkbox-input v-model="stayConnected" label="Rester connecté avec ce compte"></flat-checkbox-input>
        </div>
        <div class="flex w-full">
          <button
              @click="handleSend"
              :disabled="loading"
              :class="{
              'bg-primary-500 text-white hover:bg-primary-900': !loading,
              'bg-gray-600 text-white': loading,
            }"
              class="my-auto mx-auto sm:mr-0 rounded-full px-6 py-2 transition relative flex flex-nowrap items-center">
            <span class="w-7px h-7px bg-white animate-ping rounded-full mr-3 -ml-2" v-if="loading"></span>
            Je me connecte
          </button>
        </div>
      </div>
    </app-form>
    <div class="mb-4 text-gray-300 text-center font-light text-sm">
      <p class="mb-3">Pas encore de compte ?
        <router-link :to="{name: $routeNames.SignIn, params: $route.params}" class="cursor-pointer text-primary-900 font-normal">Inscrivez-vous
        </router-link>
      </p>
      <p class="mb-1">Impossible de vous connecter ?
        <router-link :to="{name: $routeNames.ForgotRequest}" class="underline cursor-pointer hover:text-primary-900">
          mot de passe oublié
        </router-link>
      </p>
      <p class="mb-1">Si vous rencontrez un problème
        <router-link :to="{name: $routeNames.Contact}" class="underline cursor-pointer hover:text-primary-900">
          contactez-nous
        </router-link>
      </p>
      <p>
        <span class="underline cursor-pointer hover:text-primary-900" @click="openCgu">
          Conditions générales d'utilisation
        </span>
      </p>
    </div>
  </div>
</template>

<script>
import Vue from 'vue';
import { Component } from 'vue-property-decorator';
import { mapState } from 'vuex';
import FlatBasicInput from '@/components/form-input/flat-basic-input.vue';
import FlatCheckboxInput from '@/components/form-input/flat-checkbox-input.vue';
import AppForm from '@/components/form-input/app-form.vue';
import { alertDanger, alertSuccess, alertWarning } from '../../_helpers/alert.helper';
import MetaHelper from '../../_helpers/meta.helper';

@Component({
  name: 'LogIn',
  metaInfo() {
    return MetaHelper.login();
  },
  components: {
    AppForm,
    FlatCheckboxInput,
    FlatBasicInput,
  },
  data() {
    return {
      email: '',
      password: '',
      stayConnected: false,
    };
  },
  computed: {
    ...mapState({
      loading: (s) => s.auth.loading,
      error: (s) => s.auth.error,
    }),
  },
  beforeDestroy() {
    this.resetForm();
    this.$store.commit('auth/SET_ERROR', '');
    this.$store.commit('auth/SET_LOADING', false);
  },
  methods: {
    openCgu() {
      this.$store.commit('globalModal/cgu/SET_OPEN', true);
    },
    resetForm() {
      this.stayConnected = false;
    },
    async handleSend() {
      try {
        await this.$store.dispatch('auth/connect', {
          email: this.email,
          password: this.password,
          stayConnected: this.stayConnected,
        });
        this.resetForm();
        if (this.$store.getters['auth/isAuth']) {
          alertSuccess('Vous êtes maintenant connecté(e)', 2000);
          return this.$router.push(this.$route.params.redirect || { name: 'Search' });
        }
        alertWarning('Impossible de vous connecter, identifiants invalides', 2000);
      } catch (e) {
        alertDanger('Une erreur est survenue, veuillez vérifier vos informations puis essayez à nouveau', 5000);
      }
      return null;
    },
  },
})
export default class LogIn extends Vue {
}
</script>

<style scoped>

</style>
