<template>
  <div class="input-container relative cursor-pointer items-center flex flex-nowrap items-center"
       :class="{mousedown: isMouseDown, active: internalValue}"
       @mouseleave="handleLeave"
       @click="toggle"
       @mousedown="handleMouseDown">
    <div class="border-2 border-gray-400 bg-transparent rounded h-20px w-20px mr-4 ml-2 flex-shrink-0 flex-grow-0 relative transform transition"
      :class="{'scale-1': !isMouseDown, 'scale-95': isMouseDown}">
      <div class="absolute left-2px right-2px bottom-2px top-2px rounded-sm bg-primary-500 transition transform"
        :class="{'scale-1': internalValue, 'scale-0': !internalValue}"></div>
    </div>
    <div class="label text-sm italic text-gray-400"><span></span>{{ label }}<slot></slot></div>
  </div>
</template>

<script>
import Vue from 'vue';
import { Component } from 'vue-property-decorator';

@Component({
  name: 'flat-checkbox-input',
  props: {
    label: {
      type: String,
      required: true,
    },
    value: {
      required: false,
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      internalValue: false,
      isMouseDown: false,
    };
  },
  watch: {
    value(v) {
      this.setValue(!!v);
    },
  },
  methods: {
    handleLeave() {
      this.isMouseDown = false;
    },
    handleMouseDown() {
      this.isMouseDown = true;
    },
    toggle() {
      this.setValue(!this.internalValue);
    },
    setValue(v) {
      this.internalValue = v;
      this.isMouseDown = false;
      this.$emit('input', v);
    },
  },
})
export default class CheckboxInput extends Vue {
}
</script>

<style scoped lang="scss">
</style>
